<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Pagination from "@/components/widgets/pagination";

import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";

import store from "@/state/store";
import {apiUserMethods} from "@/state/helpers";
import Loader from "@/components/widgets/loader";
import RolesModal from "@/components/modals/roles-modal";

// 검색 기본값
const BASE_SEARCH = {
  field: 'fixedcrypt:phone+name+email',
  keyword: '',
  status: 'Registered',
  page: 1,
}

export default {
  components: {
    Layout,
    PageHeader,
    Pagination,
    Loader,
    RolesModal,
  },
  data() {
    return {
      menuId: 200200,
      menuCode: "admin",
      currentPage: 1,
      totalCount: 0,
      listData: [],
      isPageLoading: false,
      search: BASE_SEARCH,
      states: [
        { value: 'Registered', text: '가입회원' },
        { value: 'SignOut', text: '탈퇴회원' },
      ],
      showModal: {
        roles: false,
      },
      modalData: {},
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    Tablesaw._init();
  },
  beforeRouteUpdate(to, from, next) {
    if(to.fullPath !== from.fullPath) {
      this.initSearch(to.query)
      next();
    }
  },
  methods: {
    ...apiUserMethods,

    onRoutechange() {
      this.initSearch(this.$route.query)
      this.loadData()
    },

    // 데이터 추출
    loadData() {
      let page = this.search.page
      let type = 'admins'

      // 검색 조건 결합
      let params = {
        ...this.$route.query,
        ...this.search,
        page: page
      };
      // console.log(params);

      this.isPageLoading = true
      store.dispatch("apiUsers/list", { type, params }).then((result) => {
        this.updateTableData(result.data, result.recordsFiltered)
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      })
    },

    // 데이터 가공
    updateTableData(data, total) {
      this.listData = data;
      this.totalCount = total;

      this.$nextTick(function () {
        // Tablesaw가 동적으로 처리되지 않아 별도 처리
        reloadTablesaw(this.$refs.tablesaw)
      })
    },

    // 라우트 변경 ( 브라우져 뒤로가기 구현 )
    changeRouter() {
      let page = this.search.page
      let params = { page: page };

      // 쿼리스트링 결합
      let query = {
        ...this.$route.query,
        ...this.search,
        ...params
      };

      // 히스토리 추가
      this.$router.push({ query })
    },

    // 페이징 처리
    changePage(page) {
      if (this.search.page != page) {
        this.currentPage = this.search.page = page
        this.changeRouter()
      }
    },

    // 검색 초기값 설정
    initSearch(routeQuery) {
      this.search = Object.assign({}, {...BASE_SEARCH, ...routeQuery})
      this.search.page = this.search.page * 1
      this.currentPage = this.search.page
    },

    // 검색 실행
    searchData() {
      this.currentPage = this.search.page = 1

      // 라우트가 변경되면 loadData 호출
      this.changeRouter()
    },

    // 회원상태 검색
    searchStatus(status) {
      this.search.status = status
      this.searchData()
    },

    // 현재 설정된 회원상태 스타일 변경
    variantStatus(status) {
      if (this.search.status == status) {
        return "info"
      } else {
        return "outline-secondary"
      }
    },

    openRoles(data) {
      this.modalData = data
      this.showModal.roles = true
    },

  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <roles-modal
        v-if="showModal.roles"
        :modal-data="modalData"
        @close="showModal.roles = false"
        @loadData="loadData"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card min-content">
          <div class="card-body">
            <div>
              <b-form class="px-2" @submit.prevent="searchData">
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">가입상태</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 w-auto text-nowrap">
                      <b-button
                          v-for="state in states"
                          :key="state.value"
                          :variant="variantStatus(state.value)"
                          @click="searchStatus(state.value)"
                          class="spacing-10 me-1"
                      >{{ state.text }}</b-button>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">키워드 검색</label>
                  <div class="col-sm input-group text-nowrap">
                    <b-form-input
                        for="keyword"
                        v-model="search.keyword"
                        placeholder="이름,아이디 또는 휴대폰을 입력하세요."
                        class="form-control"
                        style="width: 200px"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row justify-content-between pb-3">
                  <div class="col">
                    <b-button type="submit" variant="primary">검색하기</b-button>
                  </div>
                  <div class="col text-end">
                    <router-link to="/users/admins/create" class="btn btn-danger ms-2">등록하기</router-link>
                  </div>
                </div>
              </b-form>
              <div class="position-relative" style="min-height: 130px;">
                <table ref="tablesaw" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" class="wd-60" data-tablesaw-priority="5">순번</th>
                    <th scope="col" class="wd-100" data-tablesaw-priority="persist">아이디</th>
                    <th scope="col" class="text-start" data-tablesaw-priority="persist">이름</th>
                    <th scope="col" class="wd-60" data-tablesaw-priority="4">가입상태</th>
                    <th scope="col" class="wd-100" data-tablesaw-priority="persist">최종접속일시</th>
                    <th scope="col" class="wd-100" data-tablesaw-priority="3">등록일시</th>
                    <th scope="col" class="wd-40" data-tablesaw-priority="persist">권한</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="list in listData" :key="list.uid">
                    <td>{{ list.rownum }}</td>
                    <td>{{ list.email }}</td>
                    <td class="text-start ellipsis">
                      <router-link :to="`/users/admins/${list.uid}`">
                       <span><strong>{{ list.name }}</strong> ({{ list.phone }})</span>
                      </router-link>
                    </td>
                    <td>
                      <span v-if="list.status == 'Waiting'" class="text-secondary">대기</span>
                      <span v-else-if="list.status == 'Registered'" class="text-success">승인</span>
                      <span v-else-if="list.status == 'SignOut'" class="text-danger">탈퇴</span>
                      <span v-else>-</span>
                    </td>
                    <td class="spacing-10">
                      <span v-if="list.last_login_at">{{ list.last_login_at }}</span>
                      <span v-else>-</span>
                    </td>
                    <td class="spacing-10">{{ list.join_date }}</td>
                    <td>
                      <b-button size="sm" variant="outline-primary" class="btn-rounded spacing-5 me-1" @click="openRoles(list)">권한</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="position-absolute base-list-message pt-5" v-show="!isPageLoading && listData.length === 0">조회된 내역이 없습니다.</div>
                <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-5"></Loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <Pagination :total="totalCount" v-model:page="currentPage" @changePage="changePage($event)"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
