<script>
import store from "@/state/store";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core/dist/index.esm";
import Loader from "@/components/widgets/loader";

export default {
  name : 'RolesModal',

  setup() {
    return { v$: useVuelidate() };
  },

  props : ["modalData"],

  components: {
    Loader,
  },

  data() {
    return {
      title: "권한설정",
      showModal: false,
      user: this.modalData,
      roles: [],
      userRoles: [],
      formData: {
        uid: "",
        roles: [],
      },
      checks: [],
      formSubmitted: false,
      isPageLoading: false,
    }
  },

  mounted() {
    this.title = this.user.name +" "+ this.title

    this.loadData()
  },

  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },

    loadData() {
      this.showModal = true

      store.dispatch("apiRoles/list").then((result) => {
        this.roles = result.data.items
      })

      let uid = this.user.uid
      this.isPageLoading = true
      store.dispatch("apiRoles/userRoles", uid).then((result) => {
        if (result.data) {
          this.userRoles = result.data;
          setTimeout(() => {
              this.checkData(this.userRoles);
          },1000)
          this.isPageLoading = false
        }
      })
    },

    checkData(roles) {
      roles.forEach((val) => {
        let item = document.querySelector("input[name='roles[]'][value='"+ val +"']");
        if (item) {
          item.setAttribute("checked",'checked');
        }
      });
    },

    checkItem(role) {
      if (this.userRoles.find(v => v === role)) {
        this.userRoles = this.userRoles.filter(v => v !== role)
      } else {
        this.userRoles.push(role)
      }
    },

    // 저장
    submitForm() {
      store.dispatch('notification/clear')

      this.formSubmitted = true

      let params = {
        uid: this.user.uid,
        roles: this.userRoles,
      }

      store.dispatch("apiRoles/store", params).then((result) => {
        this.formSubmitted = false
        Swal.fire(result.message, "", result.success ? "success" : "error");
        this.$emit('loadData');
        document.querySelector("#btn-cancel").click();
      }, () => {
        this.formSubmitted = false
      });
    },
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  }
}
</script>

<template>
  <b-modal
      id="my-modal"
      v-model="showModal"
      @show="openModal"
      @hidden="closeModal"
      centered
      :title="title"
      title-class="font-size-14"
      hide-footer
    >
    <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
    <b-form @submit.prevent="submitForm">
      <div class="position-relative" v-show="!isPageLoading">
        <div v-for="role in roles" :key="role.id" class="mb-2 form-control">
          <b-form-checkbox
              name="roles[]"
              :value="role.code"
              @click="checkItem(role.code)"
          ><span class="text-dark">{{ role.name }}</span>
          </b-form-checkbox>
          <div class="font-size-13 text-secondary"> ※ {{ role.description }}</div>
        </div>
        <div class="mb-2 form-control">
          <b-form-checkbox
              name="roles[]"
              value="admin:super"
              @click="checkItem(`admin:super`)"
          ><span class="text-dark">슈퍼관리자</span>
          </b-form-checkbox>
          <div class="font-size-13 text-secondary"> ※ 모든 권한을 허용합니다.</div>
        </div>
      </div>
      <div class="position-relative pt-3 pb-5" v-show="isPageLoading">&nbsp;</div>
      <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-n5"></Loader>
      <div class="mt-3 mb-2 text-center">
        <b-button variant="primary" class="mx-1" type="submit" :disabled="formSubmitted">권한 변경</b-button>
        <b-button variant="secondary" class="mx-1" id="btn-cancel" data-bs-dismiss="modal">취소</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
